import React from "react";
import { Markup } from "interweave";

const Lead = ({ data }) => (
  <section>
    <div className=" blue-bg py-5">
      <div className="container">
        <Markup content={data} />
      </div>
    </div>
  </section>
);
export default Lead;
