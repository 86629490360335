import React from "react";

const TermConditionsPage = () => (
  <section className="section py-5 bg-light">
    <div className="container py-4">
      <div>
        <div>
          <h2>Terms and Conditions</h2>
          <h4 className="mt-5 mb-3">Parties</h4>
          <p className="small">
            We are <strong>FINBLOCKS LTD</strong>, a limited company
            incorporated in England with registered number 11269670 and
            registered address at 10 Bolt Court, London, United Kingdom, EC4A 3DQ
            (“<strong>FinBlocks</strong>”, “<strong>we</strong>” and “
            <strong>us</strong>”).
          </p>
          <p className="small">
            These Terms together with the sign-up form that you complete when
            you create a user account with the Platform create a binding
            contract between you and us. References to the “
            <strong>User</strong>” or “<strong>you</strong>” herein are to you,
            the user of the Platform.
          </p>
          <h4 className="mt-5 mb-3">Regulatory information</h4>
          <p className="small">
            FinBlocks is currently pending FCA registration.
          </p>
          <p className="small">
            FinBlocks is currently pending HMRC registration.
          </p>
          <h4 className="mt-5 mb-3">The Platform</h4>
          <p className="small">
            FinBlocks provides and operates the Platform through which the User
            may access the Payment Services.
          </p>
          <p className="small">
            FinBlocks holds client monies in a segregated client account with
            its banking provider. Monies belonging to different clients are
            pooled in the segregated client account.
          </p>
          <h4 className="mt-5 mb-3">Purpose</h4>
          <p className="small">
            These Terms (the “<strong>Terms</strong>”) set out the terms and
            conditions according to which the User accesses and uses the
            Platform and the Payment Services.
          </p>
          <p className="small">
            The User may, at any time and at no cost, obtain a copy of these
            Terms by accessing the Platform website at{" "}
            <a href="http://www.finblocks.net">www.finblocks.net</a>.
          </p>
          <p className="small">
            Please review these Terms carefully and make sure that you
            understand them before using the Platform. If you do not agree to
            these Terms, you must cease use of the Platform immediately.
          </p>
          <p className="small">
            By clicking to agree to these Terms, you agree to be bound by these
            Terms in their entirety. You also agree to the privacy policy which
            can be viewed on the Platform Website, and agree to FinBlocks’ use
            of cookies set out therein.
          </p>
          <p className="small">
            FinBlocks may amend these Terms from time to time. Any amendments to
            the Terms shall be come into effect at the expiry of one (1)
            calendar month after you have been notified of such amendments
            either by email or via the Platform, and shall be binding upon you
            from such time. Should you object to any amendment to the Terms then
            you must cease use of the Platform prior to the expiry of the one
            month notice period.
          </p>
          <h4 className="mt-5 mb-3">Definitions</h4>
          <p className="small">
            Capitalised terms used in these Terms shall have the following
            meanings:
          </p>
          <p className="small">
            “<strong>Accepted Payment Methods</strong>” means a payment by Card,
            by bank wire transfer or by any other payment method that is
            accepted by FinBlocks from time to time in order to pay funds into a
            Payment Account or make a Payment Transaction;
          </p>
          <p className="small">
            “<strong>Bank</strong>”, or “<strong>Banks</strong>” means credit
            institutions that hold funds paid by the User into a Payment Account
            for the purposes of FinBlocks providing the Payment Services to the
            User. These funds are held in a client money account that has been
            opened for this purpose. FinBlocks reserves the right to select any
            other credit institution based in the United Kingdom. The current
            list of the selected credit institutions is available upon request
            from FinBlocks;
          </p>
          <p className="small">
            “<strong>Business Day</strong>” means any day except Saturday,
            Sunday, bank or other public holidays in England and Wales;
          </p>
          <p className="small">
            “<strong>Card</strong>” means a bank, payment or credit card linked
            to a Card Issuer;
          </p>
          <p className="small">
            “<strong>Card Issuer</strong>” means either the Visa or Mastercard
            networks;
          </p>
          <p className="small">
            “<strong>Credit Page</strong>” means the page on the Platform
            Website used for the purpose of crediting the Payment Account;
          </p>
          <p className="small">
            “<strong>Data Protection Laws</strong>” means the Data Protection
            Act 2018, the General Data Protection Regulation (EU) 2016/679, the
            ePrivacy Directive 2002/58/EC and such data protection laws as may
            be in place in the United Kingdom from time to time;
          </p>
          <p className="small">
            “<strong>Payee</strong>” means the payee in any given Payment
            Transaction;
          </p>
          <p className="small">
            “<strong>Payer</strong>” means the payer in any given Payment
            Transaction;
          </p>
          <p className="small">
            “<strong>Payment Account</strong>” means a Sterling account held
            with the Platform in the name of the User which is used for the
            purposes of executing Payment Transactions. The Payment Account is
            held with a Bank, and is kept ring-fenced from FinBlocks’ own funds;
          </p>
          <p className="small">
            “<strong>Payment Page</strong>” means a page hosted on a Website for
            the purpose of executing Payment Transactions;
          </p>
          <p className="small">
            “<strong>Payment Services</strong>” means the services provided
            through the Platform, including but not limited to:
          </p>
          <ol type="a">
            <li className="small">
              the opening and management of a Payment Account;
            </li>
            <li className="small">
              the crediting of funds to the Payment Account that have been
              transferred by Accepted Payment Methods by the User or any other
              Party; and
            </li>
            <li className="small">
              the execution of a Payment Transaction or the payment of any fees
              pursuant to these Terms.
            </li>
          </ol>
          <p className="small">
            “<strong>Payment Transaction</strong>” means an act initiated by the
            Payer of placing, transferring or withdrawing funds, irrespective of
            any underlying obligations between the Payer and the Payee;
          </p>
          <p className="small">
            “<strong>Personal Data</strong>” has the meaning given to it in the
            Data Protection Laws;
          </p>
          <p className="small">
            “<strong>Platform</strong>” means the payment platform operated by
            FinBlocks, which may be accessed via the Platform Website, or by
            such other means as may be provided by FinBlocks from time to time;
          </p>
          <p className="small">
            “<strong>Platform Website</strong>” means the website hosted by
            FinBlocks for the purpose of managing the Payment Account and
            executing Credit Transactions and Payment Transactions;
          </p>
          <p className="small">
            “<strong>Pricing Conditions</strong>” means the document comprising
            all the fees payable by the User for the use of the Payment
            Services. The Pricing Conditions are as stated on the Platform
            Website;
          </p>
          <p className="small">
            “<strong>Registration</strong>” means the input of the User’s
            details into the Platform Website for the purpose of registering
            with the Platform;
          </p>
          <p className="small">
            “<strong>Transaction Data</strong>” means data concerning Payment
            Transactions made by the User;
          </p>
          <p className="small">
            “<strong>Unauthorised Transaction</strong>” means a Payment
            Transaction executed as a result of the unauthorised use of the
            User’s Payment Account by a third party other than by reason of the
            User’s fraud, negligence or wilful default or breach of these Terms;
            and
          </p>
          <p className="small">
            “<strong>Website</strong>” means any website through which the User
            may execute a Payment Transaction, as listed on the Platform
            Website.
          </p>
          <p className="small">
            A reference to “<strong>he</strong>”, “<strong>him</strong>” or “
            <strong>his</strong>” shall as applicable include or be treated as a
            reference to “<strong>she</strong>”, “<strong>her</strong>” or “
            <strong>hers</strong>”.
          </p>
          <h4 className="mt-5 mb-3">Opening a Payment Account</h4>
          <p className="small">
            In order to make use of the Platform and the Payment Services, you
            must register a Payment Account on the Platform Website.
          </p>
          <p className="small">
            To be eligible to register a Payment Account, you must:
          </p>
          <p className="small">
            be a natural person of at least 18 (eighteen) years of age, with the
            capacity to enter into legally binding contracts (a “
            <strong>Natural User</strong>”); or
          </p>
          <p className="small">
            be a legal person resident or registered in the United Kingdom, a
            member state of the European Union, a state that is party to the
            agreement relating to the European Economic Area, or in a third
            country that imposes the equivalent requirements regarding money
            laundering and the financing of terrorism (a “
            <strong>Legal User</strong>”).
          </p>
          <p className="small">
            In order to register a Payment Account, a Natural User must input or
            upload to the Platform Website the following details:
          </p>
          <ol type="a">
            <li className="small">bank account information;</li>
            <li className="small">
              a copy of a currently valid official document proving the User’s
              identity, for instance, an official identity card, driving licence
              or passport;
            </li>
            <li className="small">personal address;</li>
            <li className="small">email address;</li>
            <li className="small">date of birth; and</li>
            <li className="small">nationality,</li>
          </ol>
          <p className="small">
            or such other documentation and/or information as FinBlocks may
            require, in its sole and absolute discretion.
          </p>
          <p className="small">
            In order to create a Payment Account, a Legal User must input or
            upload to the Platform Website the following details:
          </p>
          <ol type="a">
            <li className="small">bank account information;</li>
            <li className="small">company name;</li>
            <li className="small">corporate form;</li>
            <li className="small">registered office address;</li>
            <li className="small">correspondence email address;</li>
            <li className="small">a description of its business activity;</li>
            <li className="small">
              the identity of all partners and company executives;
            </li>
            <li className="small">a list of all beneficial owners;</li>
            <li className="small">
              a company registration certificate dated less than three months;
              and
            </li>
            <li className="small">the articles of association,</li>
          </ol>
          <p className="small">
            or such other documentation and/or information as FinBlocks may
            require, in its sole and absolute discretion.
          </p>
          <p className="small">
            Subsequent to the provision of the required information and
            documents by the User to the Platform and the acceptance of the
            Terms herein (a “<strong>Registration Request</strong>”), FinBlocks
            shall confirm whether the User’s Registration Request has been
            successful within three (3) Business Days by email.
          </p>
          <p className="small">
            An electronic identity check may be conducted as part of the
            verification process concerning a Registration Request. You hereby
            consent to FinBlocks (or one of its service providers) carrying out
            an electronic identity check.
          </p>
          <p className="small">
            The User declares at the time of transmission of his Registration
            Request to the Platform and for the duration of this agreement that:
          </p>
          <ol type="a">
            <li className="small">
              he is at least eighteen (18) years of age and has capacity to
              enter into legally binding contracts or, where he is a Legal User,
              that he is validly incorporated in the form of a company;
            </li>
            <li className="small">he is acting on his own behalf; and</li>
            <li className="small">
              all information provided to the Platform at the time of his
              Registration is and remains accurate and up-to-date.
            </li>
          </ol>
          <p className="small">
            In the event that you use the Platform on behalf of a company or
            other business, you represent and warrant that:
          </p>
          <ol type="a">
            <li className="small">
              you are authorised to do so by a director of such company or
              business;
            </li>
            <li className="small">
              you are authorised to bind the company or business with respect to
              transactions of the type, size and nature undertaken through the
              Platform; and
            </li>
            <li className="small">
              you acknowledge that using the Platform gives rise to legally
              binding obligations which may result in you or your employer
              incurring significant fees.
            </li>
          </ol>
          <p className="small">
            Notwithstanding any provision of this clause 6, FinBlocks may reject
            any Registration Request, close any Payment Account and withdraw the
            User’s access to the Platform for any reason, immediately and
            without notice, in its sole and absolute discretion, including if
            you provide false or incorrect information, or do not notify
            FinBlocks of any changes to your information immediately.
          </p>
          <p className="small">
            Should FinBlocks accept your Registration Request, you will be sent
            a confirmation email which will contain a link for you to confirm
            your log in and allow you to select a password for your Payment
            Account (together, the “<strong>Login Details</strong>”). You must
            protect the secrecy of your Login Details at all times and never
            disclose them to another person. You must take reasonable steps to
            keep your Login Details safe and prevent fraudulent use of your
            Payment Account. You may change your Login Details on the Platform
            Website, if required.
          </p>
          <p className="small">
            If you lose or have your Login Details stolen, you must contact
            FinBlocks by email. FinBlocks will re-issue Login Details in
            accordance with their security procedures as updated from time to
            time.
          </p>
          <h4 className="mt-5 mb-3">Operation of the Payment Account</h4>
          <p className="small">
            <strong>Deposit of funds into a Payment Account</strong>
          </p>
          <p className="small">
            Any payment of funds by the User or any third party into the Payment
            Account must be made by Accepted Payment Methods. In order to credit
            the Payment Account (a “Credit Transaction”), the User must log in
            to the Platform Website and complete the Credit Page.
          </p>
          <p className="small">
            The completion of the Credit Page does not guarantee that the Credit
            Transaction will be processed and the funds credited to the Payment
            Account. The credit of funds to a Payment Account is conditioned
            upon actual receipt by the Platform of the funds minus the fees
            agreed in the Pricing Conditions.
          </p>
          <p className="small">
            The transfer of funds pursuant to a Credit Transaction is executed
            by the relevant Card Issuer. Any dispute or issue concerning a given
            Credit Transaction transfer should be notified to the relevant Card
            Issuer. FinBlocks has no authority to cancel any such Credit
            Transaction.
          </p>
          <p className="small">
            All sums credited to the Payment Account will be held in a separate
            account at the Bank, subject to the deduction of any fees payable by
            the User under these Terms.
          </p>
          <h4 className="mt-5 mb-3">Execution of Payment Transactions</h4>
          <p className="small">
            Sums debited from the Payment Account result from:
          </p>
          <ol type="a">
            <li className="small">the execution of Payment Transactions;</li>
            <li className="small">
              the collection by FinBlocks of any fees owed by the User under the
              Pricing Conditions; or
            </li>
            <li className="small">
              the reversal of a Payment Transaction made by the User by Accepted
              Payment Methods.
            </li>
          </ol>
          <p className="small">
            Payment Transactions may be initiated on the Platform Website or on
            any Payment Page following input of the Login Details (a “Payment
            Instruction”).
          </p>
          <p className="small">
            Any Payment Instruction made by the User will be deemed to have been
            received by FinBlocks upon receipt.
          </p>
          <p className="small">
            Before any Payment Instruction is executed as a Payment Transaction,
            the User may be asked to enter a single-use code notified to his
            mobile phone for input into the Platform Website or Payment Page,
            where applicable.
          </p>
          <p className="small">
            FinBlocks may, in its sole and absolute discretion, refuse to
            process any Payment Instruction. Under no circumstances shall any
            refusal to process a Payment Instruction give rise to any
            compensation to the User. Where possible, FinBlocks will provide the
            User with the reasons for refusing to process any Payment
            Instruction, but is under no obligation to do so.
          </p>
          <p className="small">
            Where the Payee’s Bank is located within the United Kingdom, a
            member state of the European Union or a state that is party to the
            agreement relating to the European Economic Area, the payment will
            be credited to the Payee’s bank account by the end of the next
            business day following receipt of the Payment Instructions. Where
            the Payee’s Bank is located outside the United Kingdom or European
            Economic Area, the date the payment is credited to the Payee’s bank
            account will depend upon the banking practices in the relevant
            country.
          </p>
          <p className="small">
            Payment Transactions will be executed in the currency notified to
            the User on the Platform Website or Payment Page, where applicable.
            The User’s issuance of a Payment Instruction shall amount to an
            acceptance of the use of that currency.
          </p>
          <h4 className="mt-5 mb-34">
            Disputes, Reporting and Blocking of the Payment Account
          </h4>
          <p className="small">
            <strong>Blocking of Login Details</strong>
          </p>
          <p className="small">
            The User must notify FinBlocks of the loss, theft, misappropriation
            or any unauthorised use of his Login Details via the Platform
            Website without undue delay (a “Report”). A Report must be made in
            accordance with the form as stated on the Platform Website.
          </p>
          <p className="small">
            The User will not be able to access the Payment Account following
            the notification of a Report to FinBlocks. Within a reasonable
            period of time following receipt of a Report, FinBlocks shall
            endeavour to provide the User with new Login Details in order that
            he may access the Payment Account.
          </p>
          <p className="small">
            Where FinBlocks has reasonable grounds to believe that there may be
            a breach in the security of your Login Details, the Payment Account
            or to comply with relevant national law, FinBlocks may suspend or
            withdraw access to your Payment Account.
          </p>
          <p className="small">
            In the event that your Payment Account is blocked in accordance with
            clause 8.3, FinBlocks will endeavour to inform the User within a
            reasonable period of time. FinBlocks accepts no liability for any
            loss incurred by a User whose Payment Account has been blocked nor
            by virtue of any delay in informing the User pursuant to this clause
            8.
          </p>
          <h4 className="mt-5 mb-3">Disputing a Payment Transaction</h4>
          <p className="small">
            In the event that you wish to dispute a given Payment Transaction (a
            “Disputed Transaction”), you may contact FinBlocks in the form as
            stated on the Platform Website (a “Disputed Transaction Notice”).
            Upon receipt of a Disputed Transaction Notice, FinBlocks shall
            investigate and determine the validity of the relevant Disputed
            Transaction and, if confirmed as an Unauthorised Transaction, the
            Payment Account will be restored to the position which existed prior
            to the Disputed Transaction.
          </p>
          <p className="small">
            In the event that any restoration of the Payment Account made in
            accordance with clause 8.5 is found to have been unduly made,
            FinBlocks reserves the right to reverse any such adjustment.
          </p>
          <p className="small">
            Should the User seek to dispute a given Payment Transaction in
            accordance with clause 8.5 he must do so within thirteen (13)
            calendar months from the date of the Disputed Transaction.
          </p>
          <p className="small">
            Subject to clauses 8.9 and 8.10, you shall not be liable for any
            losses incurred in respect of an Unauthorised Transaction where:
          </p>
          <p className="small">
            you have notified us of the loss, theft, misuse, misappropriation or
            unauthorised use of your Login Details; or
          </p>
          <p className="small">
            we have failed to provide you with the appropriate means to notify
            us (unless due to abnormal and unforeseen circumstances beyond our
            control or as a result of our compliance with a legal requirement).
          </p>
          <p className="small">
            Where an Unauthorised Transaction is made from your account, in the
            event that you:
          </p>
          <ol type="a">
            <li className="small">
              have failed to notified us of the loss, theft, misuse,
              misappropriation or unauthorised use of your Login Details; or
            </li>
            <li className="small">
              in the reasonable opinion of FinBlocks, you have failed to keep
              your Login Details safe,
            </li>
          </ol>
          <p className="small">
            you may be liable for any losses incurred as a result of the
            Unauthorised Transaction.
          </p>
          <p className="small">In the event that you:</p>
          <ol type="a">
            <li className="small">have acted fraudulently; or</li>
            <li className="small">
              have, in the reasonable opinion of FinBlocks, with intent or gross
              negligence failed to comply with these Terms,
            </li>
          </ol>
          <p className="small">
            you will be liable for all losses incurred as a result of the
            relevant Payment Transaction.
          </p>
          <p className="small">
            In the event that you wish to make a complaint concerning a Payment
            Transaction, you may contact FinBlocks by email at the email address
            provided on the Platform Website.
          </p>
          <p className="small">
            In the event that a Payment Transaction fails due to the default of
            FinBlocks, the Payment Transaction will be cancelled and the Payment
            Account will be restored to the position which existed prior to the
            Payment Transaction. FinBlocks shall then re-execute the relevant
            Payment Transaction.
          </p>
          <h4 className="mt-5 mb-3">Reporting</h4>
          <p className="small">
            You may view the balance of your Payment Account at any time by
            logging into the Platform Website. A statement of transactions made
            concerning the Payment Account can be viewed on the Platform
            Website.
          </p>
          <p className="small">
            FinBlocks shall make available upon written request made from the
            User to FinBlocks by email, a monthly Payment Account statement
            covering the previous three (3) months.
          </p>
          <h4 className="mt-5 mb-3">Security</h4>
          <p className="small">
            FinBlocks shall, at all times, endeavour to ensure that the Platform
            and its handling of Personal Data complies with the Data Protection
            Laws.
          </p>
          <p className="small">
            Whilst FinBlocks has implemented commercially reasonable technical
            and organisational measures to secure your Personal Data from
            unauthorised use, FinBlocks cannot guarantee that unauthorised third
            parties will never be able to defeat those measures. You acknowledge
            that you provide your Personal Data at your own risk.
          </p>
          <p className="small">
            FinBlocks reserves the right to temporarily suspend access to the
            Payment Account for technical, security or maintenance reasons,
            without these operations entitling you to any compensation.
            FinBlocks undertakes to limit these types of interruptions to those
            which are strictly required.
          </p>
          <p className="small">
            FinBlocks shall not be held liable by the User for any errors,
            omissions, interruptions or delays in operations carried out via the
            Platform resulting from unauthorised access to the Payment Account.
            FinBlocks shall also not be held liable for any theft, loss or
            unauthorised communication of data resulting from unauthorised
            access to the Payment Account.
          </p>
          <h4 className="mt-5 mb-3">Limitation of Liability</h4>
          <p className="small">
            FinBlocks does not seek to exclude liability for death or personal
            injury caused by any gross negligence, fraud or fraudulent
            misrepresentation on the part of FinBlocks. If any applicable
            authority holds any portion of this section to be unenforceable,
            then liability will be limited to the fullest possible extent
            permitted by applicable law.
          </p>
          <p className="small">
            FinBlocks assumes no liability or responsibility for:
          </p>
          <ol type="a">
            <li className="small">any errors or omissions in the Platform;</li>
            <li className="small">
              any fault relating to any device used to access the Platform;
            </li>
            <li className="small">
              any failures (including without limitation the ability to access
              the Platform), delays or interruptions in the Platform;
            </li>
            <li className="small">
              any losses or damages arising from the use of the Platform and/or
              the Payment Services;
            </li>
            <li className="small">
              any use of third party software or services; or
            </li>
            <li className="small">
              any Unauthorised Transaction relating to a Payment Account.
            </li>
          </ol>
          <p className="small">
            FinBlocks shall not be liable in the event that any defect or
            otherwise in the Platform results in you losing or being unable to
            access (whether temporarily or permanently) the Payment Account.
          </p>
          <p className="small">
            In no event shall FinBlocks, its shareholders, directors, officers,
            employees or agents be liable (jointly or severally) to you for loss
            of use or any special, incidental, indirect or consequential damages
            arising out of or in connection with the Platform or these Terms, on
            any theory of liability, and whether or not advised of the
            possibility of damage.
          </p>
          <p className="small">
            FinBlocks reserves the right to deliver the Platform in its sole and
            absolute discretion.
          </p>
          <h4 className="mt-5 mb-3">Obligations of User</h4>
          <p className="small">
            The User guarantees that no element of his Payment Account on the
            Platform affects the rights of third parties or is contrary to the
            law, public order and morality.
          </p>
          <p className="small">The User warrants not to:</p>
          <ol type="a">
            <li className="small">
              execute these Terms illegally or under conditions likely to
              damage, disable, overload or alter the Platform;
            </li>
            <li className="small">
              impersonate the identity of another User, person or entity,
              falsify or conceal his identity or his age or create a false
              identity for use of the Platform;
            </li>
            <li className="small">
              disseminate Personal Data or information relating to a third
              party, such as postal addresses, telephone numbers, email
              addresses and bank account details;
            </li>
            <li className="small">
              without prejudice to legal action taken by third parties,
              FinBlocks is entitled to personally undertake any legal action
              intended to repair the damage that it may have personally suffered
              due to the User’s failure to respect his obligations under these
              Terms.
            </li>
          </ol>
          <p className="small">
            In the event that the User breaches any of the Terms herein,
            FinBlocks may, in its sole and absolute discretion, suspend, remove
            and/or block the User’s access to his Payment Account.
          </p>
          <h4 className="mt-5 mb-3">Duration and Termination</h4>
          <p className="small">
            These Terms represent a rolling contract, and shall continue on an
            ongoing basis from the date that the User's Registration Request is
            accepted by FinBlocks unless terminated in accordance with the
            provisions of this clause.
          </p>
          <p className="small">
            The User may terminate these Terms at any time by written notice by
            email to FinBlocks giving no less than thirty (30) calendar days’
            notice. Upon the expiry of the notice period, the User’s Payment
            Account will be terminated and the User will immediately cease use
            of the Platform and the Payment Services.
          </p>
          <p className="small">
            FinBlocks may discontinue the Platform and/or any of the Payment
            Services at any time without prior notice. FinBlocks shall be
            permitted in its sole and absolute discretion to terminate these
            Terms in the event that you violate any of these Terms (without
            prejudice to FinBlocks’ accumulated rights against you).
          </p>
          <p className="small">
            Without affecting any other right or remedy available to it, either
            Party may terminate this agreement with immediate effect by giving
            written notice by email to the other Party if:
          </p>
          <ol type="a">
            <li className="small">
              the other Party commits a material breach of any term of these
              Terms which breach is irremediable or (if such breach is
              remediable) fails to remedy that breach within a period of three
              (3) working days after being notified in writing by email to do
              so; and
            </li>
            <li className="small">
              the other Party repeatedly breaches any of these Terms in such a
              manner as to reasonably justify the opinion that its conduct is
              inconsistent with it having the intention or ability to give
              effect to these Terms.
            </li>
          </ol>
          <p className="small">In the event of termination:</p>
          <ol type="a">
            <li className="small">
              your Payment Account will be terminated and you will immediately
              cease use of the Platform;
            </li>
            <li className="small">
              any fees due to be paid by the User to the Platform under the
              Pricing Conditions for the provision of the Payment Services shall
              remain due in accordance with these Terms;
            </li>
            <li className="small">
              the balance of the Payment Account shall, after the deduction of
              any outstanding fees due to the Platform in accordance with clause
              12.5(b), be transferred to an alternative account without undue
              delay as notified by the User to FinBlocks; and
            </li>
            <li className="small">
              FinBlocks has no outstanding obligation to transfer to you or
              allow you to view any Transaction Data or other data.
            </li>
          </ol>
          <h4 className="mt-5 mb-3">Uses Made of the Information</h4>
          <p className="small">
            <strong>
              Prevention of Money-laundering and the Financing of Terrorism
            </strong>
          </p>
          <p className="small">
            FinBlocks is compliant with the Money Laundering Regulations 2007
            and is subject to regulation by the FCA.
          </p>
          <p className="small">
            Pursuant to clause 13.1, FinBlocks may require the provision of
            information from the User concerning the origin, purpose and
            destination of any Payment Transaction or the registration of any
            Payment Account. In such cases, FinBlocks shall carry out all due
            diligence required for identifying the User and, if necessary, the
            beneficial owner of the Payment Account and/or Payment Transactions
            associated with the Payment Account.
          </p>
          <p className="small">
            In many cases this verification will happen automatically through
            the use of an electronic identification tool. You agree to the use
            of your Personal Data in connection with such electronic
            identification tools. Sometimes we may ask you to provide us with
            copies of personal identification documents (such as your passport
            or driving licence) and a proof of address (such as a bank statement
            or utility bill).
          </p>
          <p className="small">
            The User acknowledges that FinBlocks may terminate or postpone, at
            any time, the use of Login Details, access to a Payment Account or
            execution of a Payment Transaction in the absence of sufficient
            information about its purpose or nature.
          </p>
          <p className="small">
            We may be required by the Money Laundering Regulations 2007, by HM
            Revenue &amp; Customs, by the FCA or other regulatory bodies, by the
            police or other law enforcement agencies, by a court or otherwise by
            law to use your information in the detection, prevention or
            prosecution of crime, tax evasion or fraud. We may disclose any
            information provided by you to any of the foregoing bodies.
          </p>
          <p className="small">
            No legal or civil action nor professional sanction may be brought
            against FinBlocks or its respective managers or employees who have
            reported their suspicions regarding the nature of a given Payment
            Transaction to national authorities.
          </p>
          <h4 className="mt-5 mb-3">Information Checks</h4>
          <p className="small">
            We may use third-party service providers for identification checks
            on the information you provide.
          </p>
          <h4 className="mt-5 mb-3">Other</h4>
          <p className="small">
            We shall never use your Personal Data when posting on a third-party
            website unless we have your prior written consent.
          </p>
          <p className="small">
            We may use your Personal Data for the following purposes:
          </p>
          <ol type="a">
            <li className="small">
              to manage and administer the Platform and deliver the Payment
              Services;
            </li>
            <li className="small">
              to identify when you log into your Payment Account on different
              devices;
            </li>
            <li className="small">
              to improve the user experience by personalising the Platform;
            </li>
            <li className="small">to enable you to use the Platform;</li>
            <li className="small">
              to send you newsletters and other communications we think you may
              find relevant or which you have requested from us (including
              notifying you about changes to the Platform or the Payment
              Services);
            </li>
            <li className="small">
              to deal with enquiries, complaints, and feedback from you;
            </li>
            <li className="small">
              to provide third parties with anonymised statistical information
              about our users;
            </li>
            <li className="small">
              to send you emails offering you to take part in a survey and/or
              give feedback; and
            </li>
            <li className="small">
              to analyse and improve the Payment Services offered on the
              Platform.
            </li>
          </ol>
          <p className="small">
            We do not disclose information about identifiable individuals to
            third parties, but we may provide them with and sell anonymous
            aggregate information about our users. We may also use such
            aggregate information to help advertisers reach the kind of audience
            they want to target (for example, men in SW1). We may make use of
            the personal data we have collected from you to enable us to comply
            with our advertisers' wishes by displaying their advertisement to
            that target audience.
          </p>
          <h4 className="mt-5 mb-3">Personal Data</h4>
          <p className="small">
            Personal Data provided by the User when registering and operating
            his Payment Account is used by FinBlocks for the purposes of
            managing his Payment Account and executing Payment Transactions.
          </p>
          <p className="small">
            The User accepts that his Personal Data and information collected by
            FinBlocks as part of these Terms may be transmitted to operational
            providers with whom FinBlocks is in a contractual relationship for
            the sole purposes of executing Payment Transactions and the Payment
            Services, provided that these third-party recipients of Personal
            Data are subject to regulations guaranteeing a sufficient level of
            data protection in line with the requirements in the Data Protection
            Laws.
          </p>
          <p className="small">
            The list of third-party recipients of the User’s data is accessible
            upon request emailed to FinBlocks’ compliance officer at the
            following email address:{" "}
            <a href="mailto:info@finblocks.net">info@finblocks.net</a>
          </p>
          <p className="small">
            This information is kept by FinBlocks or by any company authorised
            for this purpose in accordance with legal and regulatory rules.
          </p>
          <p className="small">
            The User will be informed prior to any transfer of his Personal Data
            outside of the United Kingdom. In such cases, FinBlocks undertakes
            to respect the regulations in force and implement any necessary
            measures in order to guarantee the security and confidentiality of
            the data that has been transferred, in accordance with the
            requirements under the Data Protection Laws.
          </p>
          <p className="small">
            Certain information collected and kept by FinBlocks as part of these
            Terms may give rise to the right of access and correction. The User
            may, at any time, obtain a copy of the information concerning him
            upon request emailed to FinBlocks at the following email address:{" "}
            <a href="info@finblocks.net">info@finblocks.net</a>
          </p>
          <p className="small">
            The User may request the deletion or correction of any information
            collected in accordance with clause 14.5 in writing by email to
            FinBlocks at the email address given in clause 14.5. The User shall
            inform FinBlocks of any changes to his contact details by amending
            his Personal Data as stated on the Platform Website.
          </p>
          <p className="small">
            FinBlocks will store Personal Data for the maximum applicable legal
            or regulatory duration depending on the purpose of each type of data
            processing. Any handling, holding, processing or editing of personal
            data by FinBlocks shall be done in accordance with the rules set out
            in the Data Protection Laws.
          </p>
          <p className="small">
            The conditions for collection, holding and access of personal data
            collected by the Platform and under its responsibility when
            accessing the Platform Website are governed according to the terms
            of the privacy policy as stated on the Platform Website.
          </p>
          <h4 className="mt-5 mb-3">Notices</h4>
          <p className="small">
            Any notice given or communication made by either Party to the other
            under these Terms shall be made in writing by e-mail to the other
            Party. Any notice shall be deemed received on the same Business Day,
            provided it has been communicated within normal working hours and if
            not, then the following Business Day.
          </p>
          <p className="small">
            Unless you advise us specifically otherwise, FinBlocks may rely on
            any communication purporting to be from you, without further enquiry
            by FinBlocks as to authority or identity.
          </p>
          <h4 className="mt-5 mb-3">Force majeure</h4>
          <p className="small">
            Each Party shall not be held liable in the event of delay or
            non-execution, where such delay or non-execution results from
            events, circumstances or causes beyond its reasonable control. In
            such circumstances the affected Party must notify the other Party of
            such circumstances and following that shall be entitled to a
            reasonable extension of time to perform such obligations. If the
            period of delay or non-performance continues for four (4) calendar
            weeks then the Party not affected may terminate these Terms by
            giving seven (7) calendar days' written notice to the affected
            Party.
          </p>
          <h4 className="mt-5 mb-3">Protection of funds</h4>
          <p className="small">
            The balance of any Payment Account is protected against any claims
            from other creditors of FinBlocks, including enforcement proceedings
            or insolvency proceedings against FinBlocks.
          </p>
          <p className="small">
            The User’s funds are deposited at the end of each Business Day into
            a bank account opened with a Bank and are ring-fenced from
            FinBlocks’ funds.
          </p>
          <h4 className="mt-5 mb-3">Non-transferability</h4>
          <p className="small">
            The User is prohibited from partly or wholly transferring to a third
            party any of the rights or obligations that he holds under these
            Terms, with or without charge.
          </p>
          <p className="small">In the event of breach of this clause:</p>
          <ol type="a">
            <li className="small">
              these Terms shall immediately terminate in accordance with clause
              12; and
            </li>
            <li className="small">the User may be held liable by FinBlocks.</li>
          </ol>
          <h4 className="mt-5 mb-3">Fees</h4>
          <p className="small">
            The Payment Services offered as part of these Terms are invoiced by
            the Platform on behalf of FinBlocks in accordance with the Pricing
            Conditions.
          </p>
          <p className="small">
            All fees owed by the User are automatically deducted from the
            Payment Account by FinBlocks in accordance with the Pricing
            Conditions.
          </p>
          <h4 className="mt-5 mb-3">Proof of Transaction</h4>
          <p className="small">
            All data held in accordance with the Data Protection Laws in the
            computer database of FinBlocks, in particular relating to Payment
            Transactions, will prevail between the Parties until proven
            otherwise.
          </p>
          <h4 className="mt-5 mb-3">Intellectual Property</h4>
          <p className="small">
            FinBlocks is the sole owner of the Platform, which includes any
            software, domains, design, text, graphics and all software and
            source code connected with the Platform. FinBlocks grants you a
            limited licence to use the Platform, solely as set out in these
            Terms.
          </p>
          <p className="small">
            The Platform is protected by UK and international copyright,
            trademark, patent and other intellectual property laws.
          </p>
          <p className="small">
            Without limitation, this means that you may not sell, export,
            license, modify, copy, distribute or transmit the Platform (or any
            part of it) or any material provided through the Platform without
            FinBlocks’ prior express written consent.
          </p>
          <p className="small">
            FinBlocks grants you a limited licence to use the Platform and the
            Payment Services solely as set out in these Terms. Any unauthorised
            use of the Platform will result in the automatic termination of the
            limited licence granted by us. FinBlocks reserves the right to
            terminate the limited licence without notice at any time following
            an unauthorised use by you of the Platform.
          </p>
          <p className="small">
            FinBlocks and its graphics, logos, icons and service names related
            to the Platform are registered and unregistered trademarks or trade
            dress of FinBlocks. They may not be used without FinBlocks’ prior
            express written permission.
          </p>
          <p className="small">
            All other trademarks not owned by FinBlocks that appear in
            connection with the Platform are the property of their respective
            owners, who may or may not be affiliated with, connected to or
            sponsored by FinBlocks.
          </p>
          <h4 className="mt-5 mb-3">Disclaimer</h4>
          <p className="small">
            FinBlocks provides the Platform "as is" and without any warranty or
            condition, whether express, implied or statutory.
          </p>
          <p className="small">
            FinBlocks specifically disclaims any implied warranties of title,
            merchantability, fitness for a particular purpose and
            non-infringement.
          </p>
          <h4 className="mt-5 mb-3">Electronic Communications</h4>
          <p className="small">
            By using the Platform and/or material provided through the Platform,
            you consent to receiving electronic communications and notices from
            FinBlocks. You agree that any notice, agreement, disclosure or other
            communications that we send to you electronically will satisfy any
            legal communication requirements, including that such communications
            be in writing.
          </p>
          <h4 className="mt-5 mb-3">Privacy</h4>
          <p className="small">
            You provide us with information when you register on the Platform.
            We also collect information both relating to you (for example your
            Transaction Data) and to users of the Platform in general. Any
            information that you submit or that we collect when you are using
            the Platform or Payment Services is subject to the privacy policy as
            stated on the Platform Website, the terms of which are hereby
            incorporated into these Terms.
          </p>
          <h4 className="mt-5 mb-3">Complaints</h4>
          <p className="small">
            If you have any complaints about the provision of the Payment
            Services, please contact us using the contact form as provided on
            the Platform Website.
          </p>
          <p className="small">
            If you have any complaint about any other user, please report the
            user to our team using the reporting function on the Platform
            Website.
          </p>
          <h4 className="mt-5 mb-3">Disputes</h4>
          <p className="small">
            You agree that these Terms and any claim, dispute or controversy
            arising out of in connection with these Terms or their subject
            matter or formation (including non-contractual disputes or claims),
            the Platform, FinBlocks’ advertising or any related transaction
            between you and FinBlocks shall be governed by and construed in
            accordance with English law.
          </p>
          <p className="small">
            Any dispute or claim arising out of or in connection with such
            matters (including non-contractual disputes or claims) will be
            subject to the exclusive jurisdiction of the courts of England and
            Wales.
          </p>
          <h4 className="mt-5 mb-3">General</h4>
          <p className="small">
            These Terms are agreed between you and FinBlocks. No person shall
            have any rights under or connection with these Terms under the
            Contracts (Rights of Third Parties) Act 1999.
          </p>
          <p className="small">
            If any court or competent authority decides that any term of these
            Terms is held to be invalid, unlawful or unenforceable to any
            extent, such term shall, to that extent only, be severed from the
            remaining terms, which shall continue to be valid to the fullest
            extent permitted by law.
          </p>
          <p className="small">
            Headings are for reference purposes only and in no way define,
            limit, construe or describe the extent or scope of such section.
          </p>
          <p className="small">
            FinBlocks’ failure to enforce any provision of these Terms shall not
            constitute a waiver of that or any other provision and will not
            relieve you from the obligation to comply with such provision.
          </p>
          <p className="small">
            You are not permitted to assign, transfer, charge, sub-contract or
            deal in any other manner with all or any of your rights under these
            Terms without FinBlocks’ prior express written consent.
          </p>
          <p className="small">
            These Terms set forth the entire understanding and agreement between
            you and FinBlocks with respect to the subject matter hereof.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default TermConditionsPage;
