import React, { lazy, Suspense } from "react";
import Banner from "../components/Banner/Banner";
const GetInTouch = lazy(() => import("../components/GetInTouch/GetInTouch"));
const Illustration = lazy(() =>
  import("../components/Illustration/Illustration")
);
const SimpleSection = lazy(() =>
  import("../components/SimpleSection/SimpleSection")
);
const renderLoader = () => <p>Loading</p>;

const HowItworks = ({ data }) => {
  const { header, onboarding, processCards } = data;
  return (
    <main>
      <div className="text-center">
        <Banner data={header} />
        <Suspense fallback={renderLoader()}>
          <SimpleSection data={onboarding} />
          <Illustration data={processCards} />
          <GetInTouch />
        </Suspense>
      </div>
    </main>
  );
};

export default HowItworks;
