import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import Hero from "../components/Hero/Hero";
import Lead from "../components/Lead/Lead";
const ProductsCards = lazy(() =>
  import("../components/ProductsCards/ProductsCards")
);
const Benefits = lazy(() => import("../components/Benefits/Benefits"));
const GetInTouch = lazy(() => import("../components/GetInTouch/GetInTouch"));
const Explore = lazy(() => import("../components/Explore/Explore"));
const CaseStudy = lazy(() => import("../components/CaseStudy/CaseStudy"));
const IndustryNews = lazy(() =>
  import("../components/IndustryNews/IndustryNews")
);
const renderLoader = () => <p>Loading</p>;

const HomePage = ({ data }) => {
  const { hero, lead, benefits, follow, products, explore } = data;
  return (
    <main>
      <Helmet>
        <title>FinBlocks| Financial technology for a new era of finance</title>
        <meta
          name="description"
          content="FinBlocks is a state of the art financial platform for innovative businesses. We provide secure deposits and payment services automatically for your business via an application interface, fully supported by our dedicated team."
        />
        <meta
          name="keywords"
          content="marketplace gateway, white label escrow service, payment gateway, Payment API, uk custodian banks, third party custodian, banking as a service, custodian wealth management, banking API, Mangopay, Modulr, Rails Bank"
        />
      </Helmet>
      <div className="text-center">
        <Hero data={hero} />
        <Lead data={lead} />
        <Suspense fallback={renderLoader()}>
          <ProductsCards data={products} />
          <Lead data={follow} />
          <Benefits data={benefits} />
          <GetInTouch />
          <Explore data={explore} />
          <CaseStudy />
          <GetInTouch />
          <IndustryNews />
        </Suspense>
      </div>
    </main>
  );
};

export default HomePage;
