import React, { lazy, Suspense } from "react";
import Banner from "../components/Banner/Banner";
const CardsSection = lazy(() =>
  import("../components/CardsSection/CardsSection")
);
const GetInTouch = lazy(() => import("../components/GetInTouch/GetInTouch"));
const IndustryNews = lazy(() =>
  import("../components/IndustryNews/IndustryNews")
);
const SimpleSection = lazy(() =>
  import("../components/SimpleSection/SimpleSection")
);
const renderLoader = () => <p>Loading</p>;

const BankingPage = ({ data }) => {
  const { header, whyUs, benefits } = data;
  return (
    <main>
      <div className="text-center">
        <Banner data={header} />
        <Suspense fallback={renderLoader()}>
          <SimpleSection data={whyUs} />
          <GetInTouch />
          <CardsSection data={benefits} />
          <IndustryNews />
        </Suspense>
      </div>
    </main>
  );
};

export default BankingPage;
