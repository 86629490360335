import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";
import BankingPage from "./pages/BankingAsAService";
import HowItworks from "./pages/HowItWorks";
import FeaturesPage from "./pages/FeaturesPage";
import CompliancePage from "./pages/CompliancePage";
import TechnologyPage from "./pages/TechnologyPage";
import CompanyPage from "./pages/CompanyPage";
import TermConditionsPage from "./pages/TermdConditions";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import data from "./websiteData";

const Routes = () => {
  const {
    homePage,
    bankingPage,
    workingPage,
    featuresPage,
    compliancePage,
    technologyPage,
    companyPage,
  } = data;
  return (
    <Switch>
      <Route path="/" render={() => <HomePage data={homePage} />} exact />
      <Route
        path="/banking-as-a-service"
        render={() => <BankingPage data={bankingPage} />}
      />
      <Route
        path="/how-it-works"
        render={() => <HowItworks data={workingPage} />}
      />
      <Route
        path="/features"
        render={() => <FeaturesPage data={featuresPage} />}
      />
      <Route
        path="/compliance"
        render={() => <CompliancePage data={compliancePage} />}
      />
      <Route
        path="/technology"
        render={() => <TechnologyPage data={technologyPage} />}
      />
      <Route
        path="/company"
        render={() => <CompanyPage data={companyPage} />}
      />
      <Route
        path="/terms-and-conditions"
        render={() => <TermConditionsPage />}
      />
      <Route path="/privacy-policy" render={() => <PrivacyPolicyPage />} />
      <Route path="*" render={() => <HomePage data={homePage} />} />
    </Switch>
  );
};

export default Routes;
