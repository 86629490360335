import React, { lazy, Suspense } from "react";
import Banner from "../components/Banner/Banner";
const FeaturesSection = lazy(() =>
  import("../components/FeaturesSection/FeaturesSection")
);
const GetInTouch = lazy(() => import("../components/GetInTouch/GetInTouch"));
const IndustryNews = lazy(() =>
  import("../components/IndustryNews/IndustryNews")
);
const renderLoader = () => <p>Loading</p>;

const CompliancePage = ({ data }) => {
  const { header, complianceCards } = data;
  return (
    <main>
      <div className="text-center">
        <Banner data={header} />
        <Suspense fallback={renderLoader()}>
          <FeaturesSection data={complianceCards} />
          <GetInTouch />
          <IndustryNews />
        </Suspense>
      </div>
    </main>
  );
};

export default CompliancePage;
