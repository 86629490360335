import React, { lazy, Suspense } from "react";
import Banner from "../components/Banner/Banner";
const CaseStudy = lazy(() => import("../components/CaseStudy/CaseStudy"));
const FeaturesSection = lazy(() =>
  import("../components/FeaturesSection/FeaturesSection")
);
const GetInTouch = lazy(() => import("../components/GetInTouch/GetInTouch"));
const renderLoader = () => <p>Loading</p>;

const FeaturesPage = ({ data }) => {
  const { header, features } = data;
  return (
    <main>
      <div className="text-center">
        <Banner data={header} />
        <Suspense fallback={renderLoader()}>
          <FeaturesSection data={features} />
          <GetInTouch />
          <CaseStudy />
        </Suspense>
      </div>
    </main>
  );
};

export default FeaturesPage;
