import React, { lazy, Suspense } from "react";
import Banner from "../components/Banner/Banner";
const FormSection = lazy(() => import("../components/FormSection/FormSection"));
const MapSection = lazy(() => import("../components/MapSection/MapSection"));
const SimpleSection = lazy(() =>
  import("../components/SimpleSection/SimpleSection")
);
const renderLoader = () => <p>Loading</p>;

const CompanyPage = ({ data }) => {
  const { header, story } = data;
  return (
    <main>
      <div className="text-center">
        <Banner data={header} />
        <Suspense fallback={renderLoader()}>
          <SimpleSection data={story} />
          <FormSection />
          <MapSection />
        </Suspense>
      </div>
    </main>
  );
};

export default CompanyPage;
