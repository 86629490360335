import { Markup } from "interweave";
import React from "react";
import { Helmet } from "react-helmet";

const Banner = ({ data }) => {
  const { title, banner, text1, text2 } = data;
  return (
    <section>
      <Helmet>
        <title>{`FinBlocks| ${title}`}</title>
        <meta name="description" content={text1} />
      </Helmet>
      <div>
        <div className="banner py-lg-5">
          <div className="container py-md-5 text-lg-left">
            <div className="vh-full d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between">
              <div className="col-lg-6 py-5">
                <h2 className="mb-4 mb-lg-3">{title}</h2>
                <div className="d-none d-lg-block">
                  <Markup content={text1}></Markup>
                </div>
              </div>
              <div className="col-lg-6 py-5 text-md-right">
                <img src={banner} className="illustration" alt={title} />
              </div>
            </div>
          </div>
        </div>
        {text2 ? (
          <div className="blue-bg py-4 py-md-5">
            <div className="container py-5">
              <div className="d-block d-lg-none">
                <Markup content={text1}></Markup>
              </div>

              <Markup content={text2}></Markup>
            </div>
          </div>
        ) : (
          <div className="blue-bg py-5 d-block d-lg-none">
            <div className="container py-5">
              <Markup content={text1}></Markup>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Banner;
