import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import data from "./websiteData";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import MainNavigation from "./components/MainNav/MainNav";
import Footer from "./components/Footer/Footer";
import Routes from "./Routes";

class App extends Component {
  constructor() {
    super();
    this.state = { data };
  }

  render() {
    const { navbar, footer } = data;
    return (
      <BrowserRouter>
        <ScrollToTop />
        <MainNavigation navbar={navbar} />
        <Routes data={data} />
        <Footer data={footer} />
      </BrowserRouter>
    );
  }
}

export default App;
