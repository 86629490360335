import React from "react";
import useWindowDimensions from "./helper/useWindowDimensions";
// import Particles from "react-particles-js";
import Particles from "react-tsparticles";

const Particle = ({ height }) => {
  const { width } = useWindowDimensions();
  return (
    <div className="particle-container w-100">
      <Particles
        id="tsparticles"
        height={width > 500 ? 750 : height}
        options={{
          background: {
            color: {
              value: "#0b294b",
            },
          },
          fpsLimit: 60,
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#afd0f7",
            },
            links: {
              color: "#afd0f7",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              value: Math.trunc(width / 10),
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />
    </div>
  );
};

export default Particle;
